<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="个人资料">
        <el-form ref="infoForm" :model="userInfo" :rules="userInfoRules" label-width="80px">
          <el-form-item label="名称：" prop="username">
            <el-input v-model="userInfo.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="性别：" prop="gender">
            <el-radio-group v-model="userInfo.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手机号：">{{ userInfo.phone }}</el-form-item>
          <el-form-item>
            <el-button :type="$config.buttonType" @click="doUpdateInfo('infoForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="安全中心">
        <el-form :model="userInfo" label-width="80px">
          <el-form-item label="手机号：">{{ userInfo.phone }}</el-form-item>
          <el-form-item label="密码：">******</el-form-item>
          <el-form-item>
            <el-button :type="$config.buttonType" @click="doOpenPasswordUpdateForm">修改密码</el-button>
          </el-form-item>
        </el-form>
        <!-- 修改密码 -->
        <el-dialog title="修改密码" v-model="showPasswordUpdateForm" width="500px">
          <el-form class="form-password" ref="passwordUpdateForm" :model="passwordUpdateForm"
            :rules="passwordUpdateFormRules" label-width="120px">
            <el-form-item label="新密码：" prop="password">
              <el-input type="password" show-password placeholder="请输入新密码" v-model="passwordUpdateForm.password">
              </el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="checkPassword">
              <el-input type="password" show-password placeholder="请重新输入新密码" v-model="passwordUpdateForm.checkPassword">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button :type="$config.buttonType" @click="doUpdatePassword('passwordUpdateForm')">确认修改</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script type="text/javascript">
import { getUserInfo, updateUserInfo, updatePassword } from "@/api/user"
import { ElMessage } from 'element-plus'
import { setLocalStorage, getLocalStorage } from '@/utils/auth'

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
      } else if (value != this.passwordUpdateForm.password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      // 用户信息
      userInfo: {},
      // 用户信息修改验证
      userInfoRules: {
        username: [{ required: true, message: "请输入名称", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
      },
      // 修改密码
      passwordUpdateForm: {
        password: null,
        checkPassword: null
      },
      // 修改密码flag
      showPasswordUpdateForm: false,
      // 密码修改验证
      passwordUpdateFormRules: {
        password: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        checkPassword: [
          { required: true, message: "请确认新密码", trigger: "blur" }, 
          { validator: validatePass, trigger: 'blur' }
        ],
      },

    }
  },
  created() {
    getUserInfo().then(res => {
      if (res.code == 1) {
        this.userInfo = res.data
      }
    })
  },
  methods: {
    // 修改用户信息
    doUpdateInfo(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          updateUserInfo({username: this.userInfo.username, gender: this.userInfo.gender}).then(res => {
            if (res.code == 1) {
              ElMessage.success('修改个人信息成功')
              setLocalStorage('userInfo', this.userInfo)
            } else {
              ElMessage.error('修改个人信息失败')
              this.userInfo = getLocalStorage('userInfo')
            }
          })
        }
      })
    },
    // 打开修改密码
    doOpenPasswordUpdateForm() {
      this.passwordUpdateForm = {password: null, checkPassword: null}
      this.showPasswordUpdateForm = true
    },
    // 修改密码
    doUpdatePassword(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          updatePassword({password: this.passwordUpdateForm.password}).then(res => {
            if (res.code == 1) {
              this.showPasswordUpdateForm = false
              this.$alert('修改密码成功', {
                confirmButtonText: '确定',
                callback: () => {
                  this.$router.push('/login')
                }
              })
            }
          })
        }
      })
    },

  },
}
</script>

<style scoped lang="scss">
.form-password {
  padding: 10px;
}
</style>
